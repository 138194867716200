import {BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './pages/login';
import { Suspense, lazy, useEffect } from 'react';
import { useClearCache } from 'react-clear-cache';
import axios from 'axios';

import './styles/Buttons.css';
import './styles/Select.css';
import './styles/Message.css';
import './styles/Lead.css';
import './styles/Section.css';
import './styles/Material.css';

import IFrame from './pages/iframe/iframe';
import getMessages from './utils/getMessages';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const Dashboard = lazy(() => import('./pages/dashboard/dashboard'));
const Messages = lazy(() => import('./pages/messages/messages'));
const Leads = lazy(() => import('./pages/leads/leads'));
const OpenLead = lazy(() => import('./pages/openLead/openLead'));
const OpenMessage = lazy(() => import('./pages/openMessage/openMessage'));
const Admin = lazy(() => import('./pages/admin'));
const Franchises = lazy(() => import('./pages/franchises/franchises'));
const OpenFranchise = lazy(() => import('./pages/openFranchise/openFranchise'));
const TrashQueue = lazy(() => import('./pages/trashQueue/trashQueue'));
const OpenTrash = lazy(() => import('./pages/openTrash/openTrash'));
const Disqualified = lazy(() => import('./pages/disqualified/disqualified'));
const OpenDisqualified = lazy(() => import('./pages/openDisqualified/openDisqualified'));
const SearchResults = lazy(() => import('./pages/searchResults/searchResults'));
const NewFranchise = lazy(() => import('./pages/newFranchise/newFranchise'));
const NewLead = lazy(() => import('./pages/newLead/newLead'));
const Exceptions = lazy(() => import('./pages/exceptions/exceptions'));
const OpenException = lazy(() => import('./pages/openException/openException'));
const ExceptionForm = lazy(() => import('./pages/exceptionForm/exceptionForm'));
const ActivityLog = lazy(() => import('./pages/activityLog/activityLog'));

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  console.log(new Date('2023-04-28T18:00:00.000Z').toLocaleString())

  useEffect(() => {
    if(!isLatestVersion) {
      emptyCacheStorage()
    }
  }, []);

  useEffect(() => {
    axios.get('https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getauth').then(res => {
      let token = res.data.access_token;
      // get number of unread messages from incoming leads inbox
      axios.get('https://graph.microsoft.com/v1.0/users/lowes@transblue.org/mailFolders/inbox/messages', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        let html = res.data.value.filter(message => message.subject.includes('New Service Request'))[0].body.content;
        console.log(typeof html);
      })
    })
  })
  
  useEffect(() => {
      let timer = localStorage.getItem('timer');
      let currentTime = new Date().getTime();

      // see if more than 5 minutes have passed since last time getMessages function was called
      if(currentTime - timer > 300000) {
        console.log('5 minutes have passed')
        // function to get all new messages from incoming leads mailbox
        getMessages();

        // update timer in localStorage
        localStorage.setItem('timer', currentTime);
      }
  }, []);

  console.log(new Date('2022-12-29T15:00:00.000Z'))

  return (
      <Suspense fallback={<div>Loading....</div>}>
        <Router>
          <Route exact path='/' component={Login} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/messages' component={Messages} />
          <Route exact path='/leads' component={Leads} />
          <Route exact path='/leads/:id' component={OpenLead} />
          <Route exact path='/messages/:id' component={OpenMessage} />
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/admin/franchises' component={Franchises} />
          <Route exact path='/admin/franchises/:id' component={OpenFranchise} />
          <Route exact path='/admin/trash' component={TrashQueue} />
          <Route exact path='/admin/trash/:id' component={OpenTrash} />
          <Route exact path='/admin/disqualified' component={Disqualified} />
          <Route exact path='/disqualified/:id' component={OpenDisqualified} />
          <Route exact path='/search/:term' component={SearchResults} />
          <Route exact path='/search/:term/:phone' component={SearchResults} />
          <Route exact path='/admin/franchise/new' component={NewFranchise} />
          <Route exact path='/new' component={NewLead} />
          <Route exact path='/admin/exceptions' component={Exceptions} />
          <Route exact path='/admin/exceptions/:id' component={OpenException} />
          <Route exact path='/exception' component={ExceptionForm} />
          <Route exact path='/iframe/:territory' component={IFrame} />
          <Route exact path='/activity' component={ActivityLog} />
        </Router>
      </Suspense>
  );
}

export default App;