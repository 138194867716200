import * as msal from "@azure/msal-browser";
import { LocalSeeOutlined } from "@mui/icons-material";
import axios from "axios";
import getMessages from "./getMessages";

import CryptoJS from "crypto-js";

const msalConfig = {
  auth: {
      clientId: '3496c50b-d717-4cd4-90a4-d205e05ceb4f'
      //clientId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

async function msalLogin() {
  try {
      // need to set db to main when Lisa logs in
      const loginResponse = await msalInstance.loginPopup({});    

      let username = loginResponse.account.username;

      if(username.toLowerCase().indexOf('transblue.org') === -1) {
        return 'not a tb member'
      }

      else {
        
        await axios.get('https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getauth')
          .then(res => {
            let token = res.data.access_token;
            axios.get(`https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true`, {
              headers: {
                Authorization: `Bearer ${token}`,
                ConsistencyLevel: 'eventual'
              }
            }).then(res => {
                console.log(res)
              })
          });

        let admin = false;

        // check if users email is in admin array 
        await axios.get('https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getall?containerId=admin')
        .then(res => {
            let emails = res.data[0].emails;
            if(emails.indexOf(loginResponse.account.username.toLowerCase()) > -1) {
                admin = true;
            }
            let subadmin = res.data[0].subadmin;
            if(subadmin.indexOf(loginResponse.account.username.toLowerCase()) > -1) {
              localStorage.setItem('subadmin', true);
            }
        })

        // set admin attribute in encrypted object
        loginResponse.account.admin = admin;

        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(loginResponse.account), 'secret key 123').toString();

        localStorage.setItem('msa', cipherText);

        localStorage.setItem('access token', loginResponse.accessToken);
        localStorage.setItem('user', loginResponse.account.name);
        localStorage.setItem('email', loginResponse.account.username);

        // keep track of when messages were last retrieved
        let timer = new Date().getTime();
        localStorage.setItem('timer', timer);
        getMessages();

        // keep track of when user logs in
        localStorage.setItem('loginTime', timer);

        return username.toLowerCase().indexOf('transblue')
      }
      
  } catch (err) {
      // handle error
      return 'failed login'
  }
}

export default msalLogin;