const modalStyle = {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    width: '90%',
    maxWidth: '350px',
    pb:2,
}

export default modalStyle;