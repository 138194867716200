import axios from "axios";


function getMessages() {
    // get auth for Microsoft API
    axios.get('https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getauth')
    .then(res => {
        console.log(res)
        let token = res.data.access_token;

        // get number of unread messages from incoming leads inbox
        axios.get('https://graph.microsoft.com/v1.0/users/incomingleads@transblue.org/mailFolders/inbox/messages?$filter=isRead ne true&$count=true', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
            // axios.get(`https://graph.microsoft.com/v1.0/users/incomingleads@transblue.org/messages/AQMkADkxN2RhMWUyLWEwNmEtNDEwYS04MjUxLTgzNWE4YjBlNzNiNQBGAAADRXXYL8FQNkih6tPQqVhvyAcADcHmPm_sWEyBx86VdK1IfgAAAgEMAAAADcHmPm_sWEyBx86VdK1IfgABInIfKwAAAA==/`, {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
            // }).then(res => {
            //         console.log('MIME CONTENT:')
            //         console.log(res.data)
            //     })

            // get all unread messages from IncomingLeads inbox
            let count = res.data['@odata.count']
            // get unread messages from IncomingLeads inbox
            axios.get(`https://graph.microsoft.com/v1.0/users/incomingleads@transblue.org/mailFolders/inbox/messages?$filter=isRead ne true&$top=${count}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                let emailArr = res.data.value;
                console.log(emailArr);

                // get all messages from database
                axios.get('https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getall?containerId=messages')
                .then(res => {
                    console.log(res.data)

                    let existingMessageIds = [];
                    
                    if(res.data !== 'No items found') {
                        existingMessageIds = res.data.map(message => message.id);
                    }

                    emailArr.forEach(async message => {
                        // only add message to database if message does not already exist
                        if(existingMessageIds.indexOf(message.id) === -1) {
                            console.log('exists')
                            let container = 'messages';
    
                            let date = new Date(message.receivedDateTime).getTime();
        
                            // format email received as a readable date for message subject
                            let emailReceived = new Date(message.receivedDateTime).getTime()
                            
                            let newMessage = {
                                id: message.id,
                                name: message.from.emailAddress.name,
                                email: message.from.emailAddress.address,
                                subject: message.subject,
                                html: message.body.content,
                                receivedDateTime: date,
                                emailReceived: emailReceived,
                                originalSubject: message.subject,
                                assignedTo: '',
                                fromTrash: false,
                                noteToReps: '',
                                attachments: []
                            }
        
                            // mark message as pinned if flagged as pinned
                            if(message.categories.indexOf('PINNED') > -1) {
                                newMessage.pinned = true;
                            }
        
                            // add message to trash if flagged as nonlead
                            else if(message.categories.indexOf('NONLEAD') > -1) {
                                container = 'trashQueue'
                            }

                       
        
                        if(message.hasAttachments) {
                            console.log('has attachments')
                                axios.get(`https://graph.microsoft.com/v1.0/users/incomingleads@transblue.org/messages/${message.id}/attachments`, {
                                    headers: {
                                        Authorization: `Bearer ${token}`
                                    }
                                })
                                .then(res => {
                                    console.log(res.data)
                                    newMessage.attachments = res.data.value;
                                    axios.post(`https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/save?containerId=${container}`, newMessage)
                                })
                        }
        
                        else {
                            axios.post(`https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/save?containerId=${container}`, newMessage)
                        }
                        }

                        // mark message as read in inbox
                        axios.patch(`https://graph.microsoft.com/v1.0/users/incomingleads@transblue.org/messages/${message.id}`, {
                            isRead: true
                        }, {
                            headers: {
                                'Content-type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        })
                    })
                    
                })
          
            })
        })
    })
}

export default getMessages;