import { useEffect, useState } from 'react';
import axios from 'axios';

function IFrameLogic(props) {
    let territory = props.match.params.territory;

    if(territory === 'mtvernon') {
        territory = 'mount vernon'
    }

    const [unfilteredData, setUnfilteredData] = useState({})
    const [data, setData] = useState({});

    let firstOfMonth = new Date();
    firstOfMonth.setDate(1);
    let today = new Date();

    const [timeRange, setTimeRange] = useState([firstOfMonth.getTime(), today.getTime()]);
    const [btnText, setBtnText] = useState(`${firstOfMonth.toLocaleDateString()} - ${today.toLocaleDateString()}`);

    useEffect(() => {
        // api call to get all scheduled, disqualified, and open leads
        // api call to get franchises
        let promises = [
            axios.get(`https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getall?containerId=scheduled`), 
            axios.get(`https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getall?containerId=disqualified`), 
            axios.get(`https://my-tb-cors.herokuapp.com/https://callblue-fns.azurewebsites.net/api/getall?containerId=leads`)
        ]

        // execute all promises
        Promise.all(promises)
            .then(res => {
                console.log(res)

                // update state with scheduled, disqualified, and open leads
                setData({
                    scheduled: res[0].data.filter(lead => lead.territory.toLowerCase() === territory && new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1]),
                    disqualified: res[1].data.filter(lead => lead.territory.toLowerCase() === territory && new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1]),
                    leads: res[2].data.filter(lead => lead.territory && lead.territory.toLowerCase() === territory && new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1])
                });

                // set unfiltered data to reference if user selects different filter
                setUnfilteredData({
                    scheduled: res[0].data.filter(lead => lead.territory.toLowerCase() === territory),
                    disqualified: res[1].data.filter(lead => lead.territory.toLowerCase() === territory),
                    leads: res[2].data.filter(lead => lead.territory && lead.territory.toLowerCase() === territory),
                });
            })
    }, []);

    function updateDate(range) {
        let start = new Date(range.startDate).toLocaleDateString();
        let end = new Date(range.endDate).toLocaleDateString();

        // get seconds of time for start end end of date range picked to compare leads to
        setTimeRange([new Date(range.startDate).getTime(), new Date(range.endDate).getTime()]);

        setBtnText(`${start} - ${end}`);
    }

    function updateData() {
        setData({
            scheduled: unfilteredData.scheduled.filter(lead => lead.emailReceived > timeRange[0] && lead.emailReceived < timeRange[1]),
            disqualified: unfilteredData.disqualified.filter(lead => lead.emailReceived > timeRange[0] && lead.emailReceived < timeRange[1]),
            leads: unfilteredData.leads.filter(lead => lead.emailReceived > timeRange[0] && lead.emailReceived < timeRange[1])
        });
    }

    return {
        updateDate,
        updateData,
        btnText,
        data,
        unfilteredData,
        timeRange
    }
}

export default IFrameLogic;