import { Autocomplete, Button, Grid, Paper, TextField, Card, TableBody, TableRow, TableCell, TableHead, Table, Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IFrameLogic from './iframeLogic';
import { DateRangePicker } from "materialui-daterange-picker"
import { CalendarMonth, Translate } from '@mui/icons-material';
import {Chart, registerables} from 'chart.js';
Chart.register(...registerables);

let leadStatusChart = null;
let conversionChart = null;

function IFrame(props) {
    console.log(props)
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const [conversionRate, setConversionRate] = useState(0);
    const [loading, setLoading] = useState(true);
    const [statusLoading, setStatusLoading] = useState(true);
    const [leadSource, setLeadSource] = useState({});

    const statuses = [
        'Unresponsive',
        'Contacted',
        "U - Small Project",
        "U - Customer Declined",
        "U - No Project",
        "U - Wrong Trade",
        "U - Small Budget",
        "U - Wrong Contact",
        "U - Out of Area",
        "U - Cancelled Project",
        "U - Customer Declined"
    ];

    const conduits = [
        'Networx',
        'CraftJack',
        'Porch',
        'ThumbTack',
        'Incoming Call',
        'ANGI Ads',
        'ANGI Leads',
        'Bark',
        'Buildingconnected',
        'BuildZoom',
        'BXWA',
        'Commercial',
        'CraftJack',
        'Existing Client',
        'Google',
        'Home Guide',
        'Houzz',
        'Incoming Email',
        'Jobsite Sign',
        'Mailer',
        'Next Door',
        'Office Sign',
        'Porch',
        'Referral',
        'Smith',
        'Trade Show',
        'Truck',
        'Website',
        'Yelp'
    ]
  
    const {
        updateData,
        updateDate,
        btnText,
        data,
        timeRange
    } = IFrameLogic(props);

    function createCharts() {
        updateData();
    }

    function changeTime(range) {
        updateDate(range);
    }
    
    useEffect(() => {
        console.log(data);
        if(conversionChart !== null) {
            conversionChart.destroy();
        }

        if(Object.keys(data).length > 0) {
            setLoading(false);
            const ctx = document.getElementById("myChart").getContext('2d');
            const scheduled = data.scheduled.length;
            const disqualified = data.disqualified.length;
            const leads = data.leads.length;
            const total = scheduled + disqualified + leads;
            setConversionRate((scheduled / total).toFixed(2) * 100);
            conversionChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Total Leads', 'Scheduled', 'Disqualified'],
                    datasets: [{
                        label: '# Leads',
                        data: [total, scheduled, disqualified],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0
                            }
                        }
                    }
                }
            });
        }

    }, [data])

    useEffect(() => {
        if(leadStatusChart !== null) {
            leadStatusChart.destroy();
        }

        if(Object.keys(data).length > 0) {
            console.log(data)
            let allLeads = [...data.scheduled, ...data.disqualified, ...data.leads];

            let object = {};
            statuses.forEach(status => {
                object[status] = allLeads.filter(lead => lead.leadStatus === status && new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1]).length
            })

            console.log(object);
            let leadStatus = object;

            setStatusLoading(false);
            const ctx2 = document.getElementById("leadStatus").getContext('2d');

            leadStatusChart = new Chart(ctx2, {
                type: 'bar',
                data: {
                    labels: Object.keys(leadStatus),
                    datasets: [{
                        label: '# Leads',
                        data: Object.values(leadStatus),
                        backgroundColor: [
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4'
                        ],
                        borderColor: [
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4',
                            '#0b56a4'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0
                            }
                        }
                    }
                }
            });
        }
    }, [data]);

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            let { scheduled, disqualified, leads } = data;

            let sourceObj = {};
            conduits.forEach(conduit => {
                let scheduledNum = scheduled.filter(lead => new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1] && lead.leadSource === conduit).length;
                let disqualifiedNum = disqualified.filter(lead => new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1] && lead.leadSource === conduit).length;
                let leadsNum = leads.filter(lead => new Date(lead.emailReceived).getTime() > timeRange[0] && new Date(lead.emailReceived).getTime() < timeRange[1] && lead.leadSource === conduit).length;

                sourceObj[conduit] = {
                    total:  scheduledNum + disqualifiedNum + leadsNum ,
                    scheduled: scheduledNum
                }
            });

            setLeadSource(sourceObj);
        }
    }, [data])

    return(
        <div style={{width: '100%', minHeight: '100vh', backgroundColor: '#f8f9fa', paddingBottom: '100px'}}>
            <Box sx={{display: 'flex', p: 4, width: '95%', mx: 'auto', pt: 5}}>
                <Button
                    onClick={toggle}
                    variant='outlined'
                    sx={{ml: 4, mr: 1, mt: 5}}
                >
                    <CalendarMonth /> {btnText}
                </Button>
                <DateRangePicker
                    open={open}
                    toggle={toggle}
                    onChange={(range) => changeTime(range)}
                />
                <Button
                    variant='contained'
                    onClick={createCharts}
                    sx={{mt: 5}}
                >
                    GET STATS
                </Button>
            </Box>

          <Grid container spacing={4} width='95%' mx='auto'>
              <Grid item xs={12}>
                <Grid
                    item>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h5'>CONVERSION RATE - {conversionRate.toFixed(2)} %</Typography>
                        <canvas id='myChart' width='100%' height='40'></canvas>
                        {loading && 
                            <Box sx={{textAlign: 'center'}}>
                                <CircularProgress />
                            </Box>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{p: 3}}>
                    <Typography variant='h5'>LEAD STATUS</Typography>
                    <canvas id='leadStatus' width='100%' height='30'></canvas>
                    {statusLoading && 
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress />
                        </Box>
                    }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{p: 3}}>
                    <Typography variant='h5'>LEAD SOURCE</Typography>
                    {!loading && 
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Lead Source</TableCell>
                                    <TableCell>Leads Created</TableCell>
                                    <TableCell>Leads Scheduled</TableCell>
                                    <TableCell>Conversion Rate</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(leadSource).map(conduit => {
                                    return (
                                        <TableRow key={conduit}>
                                            <TableCell>{conduit}</TableCell>
                                            <TableCell>{leadSource[conduit].total}</TableCell>
                                            <TableCell>{leadSource[conduit].scheduled}</TableCell>
                                            <TableCell>{
                                                leadSource[conduit].scheduled > 0
                                                ? `${((leadSource[conduit].scheduled / leadSource[conduit].total) * 100).toFixed(2)} %`
                                                : '0 %'}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    }
                    {loading && 
                        <Box sx={{textAlign: 'center'}}>
                            <CircularProgress />
                        </Box>
                    }
                </Paper>
            </Grid>
          </Grid>
        </div>
    )
}

export default IFrame;