import React, { useState } from 'react';
import msalLogin from '../../utils/msalLogin';
import logoSmall from '../../images/logoSmall.png';
import './style.css';
import { Modal, Typography, Box, Button } from '@mui/material';
import modalStyle from '../../styles/MuiModal';

const Login = props => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    async function login() {
        let loginResponse = await msalLogin();

        // handle if user logs in from different tenant than transblue
        if(loginResponse === 'not a tb member') {
            setOpen(true);
        }

        // handle if user enters the wrong password
        else if(loginResponse === 'failed login') {
            alert('Incorrect login, please try again')
        }

        else {
            props.history.push('/messages')
        }
    }


    return(
        <>
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={modalStyle}>
                <Typography variant='h5' sx={{fontWeight: 'bold', color: 'red', pt: 2, px: 2}}>
                    ALERT!!
                </Typography>
                <Typography sx={{p:2}}>You must be a member of Transblue to access this software. Please login with your Transblue account.</Typography>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        sx={{fontSize: '10px', mr: 2}}
                        color='success'
                        onClick={handleClose}
                        variant='contained'
                    >
                        got it
                    </Button>
                </Box>
            </Box>
        </Modal>
            <div className='login-bg'>
                <div className='login-wrapper centered-component'>
                    <img src={logoSmall} alt='Transblue Logo' />
                    <h3 className='text-center'>Welcome to CallBlue</h3>
                    <hr />
                    <p className='text-muted'>Press the login button below to log in to your Transblue account</p>
                    <hr />
                    <button onClick={login}>Login</button>
                </div>
            </div>
        </>
    )
}

export default Login;